import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: 'tr[nxhDisabled], td[nxhDisabled]',
})
export class DisabledDirective implements OnChanges, OnInit, OnDestroy {
  @Input() nxhDisabled = true;
  private destroy$$ = new Subject<void>();

  constructor(private el: ElementRef<HTMLTableRowElement | HTMLTableColElement>, private renderer: Renderer2) {}

  ngOnChanges(): void {
    if (this.el?.nativeElement) {
      if (this.nxhDisabled === true) {
        this.renderer.addClass(this.el.nativeElement, 'nxh-disabled');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'nxh-disabled');
      }
    }
  }

  ngOnInit(): void {
    fromEvent(this.el.nativeElement, 'click', { capture: true })
      .pipe(takeUntil(this.destroy$$))
      .subscribe((event: MouseEvent) => {
        if (this.nxhDisabled) {
          // note this does not work on child components - these are disabled via css (pointer-events: none)
          // the reason we cannot use pointer-events for the tr or td is because this does not play nicely with
          // the css rule "cursor: not-allowed" (pointer-event also restricts hover effects)
          event.stopImmediatePropagation();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
