import {
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Breakpoint, ResponsiveComponent } from './responsive.component';

@Directive({
  selector: '[nxhResponsive]',
})
export class ResponsiveDirective implements OnInit, OnDestroy {
  @Input() from: Breakpoint;

  private componentRef: ComponentRef<ResponsiveComponent>;
  private embeddedRef: EmbeddedViewRef<HTMLTableElement>;

  constructor(
    private templateRef: TemplateRef<HTMLTableElement>,
    private viewContainer: ViewContainerRef,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    const injector = Injector.create({
      providers: [{ provide: responsiveTableTemplateRefToken, useValue: this.templateRef }],
      parent: this.injector,
    });
    this.componentRef = this.viewContainer.createComponent(ResponsiveComponent, {
      injector,
    });
    this.componentRef.instance.from = this.from;
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
    this.embeddedRef?.destroy();
  }
}

export const responsiveTableTemplateRefToken = new InjectionToken<TemplateRef<HTMLTableElement>>(
  'responsiveTableTemplateRefToken'
);
