import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UiCardService } from '../../service/ui-card.service';

@Component({
  selector: 'nxh-card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardBodyComponent implements OnInit {
  @HostBinding('class')
  _hostClasses = 'card-body nxh-card-component';
  @HostBinding('class.nxh-card-body-no-top-padding')
  _noTopPadding!: boolean;

  @HostBinding('class.nxh-card-body-no-padding')
  @Input()
  noPadding = false;

  @HostBinding('class.nxh-card-body-dark')
  @Input()
  dark = false;

  private _uiCardService: UiCardService = inject(UiCardService, { optional: true });
  private _destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this._uiCardService?.cardBodyNoTopPadding$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value) => (this._noTopPadding = value));
  }
}
