import { Component, HostBinding, inject, Input } from '@angular/core';
import { responsiveTableTemplateRefToken } from './responsive.directive';

@Component({
  selector: 'nxh-responsive',
  template: ` <ng-container [ngTemplateOutlet]="templateRef"></ng-container>`,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ResponsiveComponent {
  @Input() from: Breakpoint;
  @HostBinding('class') get classes() {
    return this.from ? 'table-responsive-' + this.from : 'table-responsive';
  }

  templateRef = inject(responsiveTableTemplateRefToken);
}

export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';
