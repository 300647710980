import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EidPersonInfo, ReasonNoCardReadingType } from '@nexuzhealth/shared/eid/data-access';
import {
  AddressWithContactPoints,
  ContactInfo,
  ContactPoint,
  ContactPointChanges,
  GP,
  InssStatus,
  MigrationDirection,
  PagingResult,
  Patient,
  PatientAddress,
  PersonName,
  queryParamsForEmptyFields,
  ResourceName,
  SuggestedPatientsResponse,
  TherapeuticRelation,
} from '@nexuzhealth/shared/domain';
import { AdministrativeGenderReference } from '@nexuzhealth/shared/reference-data/data-access';
import { timeoutHeaders } from '@nexuzhealth/shared/util';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { CreateTherapeuticRelation } from '../shared/patient.service';

type PatientSearchView = 'BASIC' | 'FULL';

interface PatientSearchParams {
  searchString: string;
  orderBy: string;
  pageSize: number;
  pageToken: string;
  view: PatientSearchView;
  givenName: string;
  familyName: string;
  inss: string;
  birthDate: string;
  advanced: boolean;
  merged: boolean | undefined;
  maxNbRecords: number;
  gender: AdministrativeGenderReference;
  address: PatientAddress;
}

enum Action {
  Open,
  RegisterAr,
  RegisterTr,
  registerArAndTr,
}

export interface PatientSuggestion {
  patient: Patient;
  hasTherapeuticRelation?: boolean;
}

export interface PatientPagingResult extends PagingResult<Patient> {
  warning: string;
}

export type View = 'FULL' | 'BASIC' | 'MINIMAL';
// Default MANUAL for frontend
export type Source =
  | 'MANUAL'
  | 'UNSPECIFIED'
  | 'MYA_AGENDA'
  | 'IMPORT'
  | 'CONSULTRN'
  | 'AFSPRAAK_BE'
  | 'PATIENT_SYNC'
  | 'EID_READING';

@Injectable({
  providedIn: 'root',
})
export class PatientApiService {
  private readonly baseUrl = 'api/pm/patient/v1';
  private readonly syncpatientUrl = 'api/ehealth/syncpatient/v1';

  constructor(private http: HttpClient) {}

  /**
   * Fetches all patients that satisfy the given searchParams
   *
   * @param searchParams
   * @param pageToken
   * @param view
   * @param pageSize  defaults to 10
   * @param maxNbRecords  defaults to 499. Pass -1 to fetch *all* patients
   */
  fetchPatients(
    searchParams: Partial<PatientSearchParams> = {},
    pageToken?: string,
    view: PatientSearchView = 'BASIC',
    pageSize = 10,
    maxNbRecords = 499
  ): Observable<PatientPagingResult> {
    const url = `${this.baseUrl}/patients:search`;
    const params = {
      pageSize,
      pageToken,
      view,
      merged: false,
      maxNbRecords: maxNbRecords !== -1 ? maxNbRecords : undefined,
      ...searchParams,
    };

    return this.http.post<PatientPagingResult>(url, params).pipe(
      map((resp) => ({
        pageToken: resp['nextPageToken'],
        totalSize: resp.totalSize,
        warning: resp.warning,
        data: resp.data,
      }))
    );
  }

  fetchRecentPatients(userContextName: ResourceName): Observable<Patient[]> {
    const url = `api/pm/patient/v1/${userContextName}/recentPatients`;
    return this.http
      .post<PagingResult<Patient>>(url, {
        pageSize: 10,
      })
      .pipe(map((result) => result.data));
  }

  getPatient(patientName: string, view: View = null): Observable<Patient> {
    const url = `${this.baseUrl}/${patientName}`;
    return this.http.get<{ data: Patient }>(url, { params: { view: view ?? 'FULL' } }).pipe(pluck('data'));
  }

  savePatient(patient: Patient, source: Source) {
    const url = `${this.baseUrl}/${patient.name}`;
    let params = queryParamsForEmptyFields(patient);
    const paramVals = params?.get('updateMask') ?? '';
    const nameParams = this.queryParamsForEmptyNameFields(patient.personName);
    const nameVals = nameParams?.get('updateMask') ?? '';
    params = params.set('updateMask', [paramVals, nameVals, 'attributes'].join(','));
    params = params.set('source', source);

    return this.http
      .patch<Record<'data', Patient>>(url, patient, {
        params: params,
      })
      .pipe(map((x) => x.data));
  }

  mergePatients(data: MergePatients) {
    const url = `api/patientport/merge/v1/merge:full`;
    return this.http.post<{ data: MergePatients }>(url, data, { headers: timeoutHeaders({ errorMillis: 60000 }) });
  }

  getImportList(
    tenant: ResourceName,
    pageSize: number,
    pageToken: string,
    filters: Record<string, unknown>,
    options?: { view; type }
  ) {
    const params = {
      pageSize: pageSize.toString(),
      ...filters,
    };
    if (pageToken) {
      params['pageToken'] = pageToken;
    }
    params['view'] = options?.view ?? 'PATIENT';
    if (options?.type) {
      params['type'] = options.type;
    }
    params['direction'] = MigrationDirection.DIRECTION_IMPORT;
    const url = `api/patientport/patientmigration/v1/${tenant}`;

    return this.http.get<PagingResult<any>>(url, { params }).pipe(
      map((result) => ({
        data: result['migrations'],
        totalSize: result.totalSize,
        pageToken: result['nextPageToken'],
      }))
    );
  }

  getImportErrors(importName) {
    const url = `api/patientport/patientmigration/v1/${importName}?view=LINES_ERROR`;
    return this.http.get<any>(url);
  }

  getInssStatus(patientName: string): Observable<InssStatus> {
    const url = `${this.baseUrl}/${patientName}/inss:latest-status`;
    return this.http.get<{ inssStatus: InssStatus }>(url).pipe(pluck('inssStatus'));
  }

  createPatient(patient: Patient, createEhealthTherapeuticRelation: boolean, identifiers, reasonNoCardReading) {
    const url = `${this.baseUrl}/patients`;
    return this.http
      .post<{ data: Patient }>(url, {
        patient: patient,
        source: 'MANUAL',
        createEhealthTherapeuticRelation,
        identifiers,
        reasonNoCardReading,
      })
      .pipe(pluck('data'));
  }

  /**
   * Registers a patient with the current user and his tenant. Creates a tr and/or ar for this user/tenant.
   */
  registerPatient(patientName: ResourceName, params: RegisterPatientRequest) {
    const url = `api/pm/patient/v1/${patientName}:registerv2`;
    return this.http.post<CreateTherapeuticRelation>(url, params);
  }

  getTr(patientName: ResourceName): Observable<TherapeuticRelation> {
    const url = `api/pm/therapeuticrelation/v1/${patientName}`;
    return this.http.get(url);
  }

  //@deprecated see @see #registerPatient()
  createTr(patientName: ResourceName, motivation = null) {
    const url = `api/pm/therapeuticrelation/v1/${patientName}`;
    return this.http.post(url, { motivation });
  }

  //@deprecated see @see #registerPatient()
  createAdministrativeRelation(patientName: ResourceName) {
    const url = `${this.baseUrl}/${patientName}/createAdministrativeRelation/tenant`;
    return this.http.post(url, {});
  }

  createBisPatient(patient) {
    const url = 'api/ehealth/consultrn/v1/registerperson';
    return this.http
      .post<Record<'patient', Patient>>(url, patient, { headers: timeoutHeaders({ errorMillis: 60000 }) })
      .pipe(map((response) => response.patient));
  }

  getAddresses(patientName: string) {
    const url = `${this.baseUrl}/${patientName}/addresses`;
    return this.http.get<{ data: PatientAddress[] }>(url).pipe(pluck('data'));
  }

  getAddress(patientAddressName: string): Observable<PatientAddress> {
    const url = `${this.baseUrl}/${patientAddressName}`;
    return this.http.get<{ data: PatientAddress }>(url).pipe(pluck('data'));
  }

  saveAddress(patientName: string, address: PatientAddress) {
    const url = `${this.baseUrl}/${address.name}`;
    return this.http.patch<{ data: PatientAddress }>(url, address).pipe(pluck('data'));
  }

  addAddress(patientName: string, address: PatientAddress) {
    const url = `${this.baseUrl}/${patientName}/addresses`;
    return this.http.post<{ data: PatientAddress }>(url, address).pipe(pluck('data'));
  }

  deleteAddress(address: PatientAddress) {
    const url = `${this.baseUrl}/${address.name}?sendVersion=${address.sendVersion}`;
    return this.http.delete(url);
  }

  deleteAddressWithContact(address: PatientAddress) {
    const url = `${this.baseUrl}/${address.name}:with-contact-points?sendVersion=${address.sendVersion}`;
    return this.http.delete(url);
  }

  updateContactInfo(patientName: string, contactInfo: ContactInfo): any {
    // const url = `/api/patients/${patientName}/contact-info`;
    const url = `/mock/patients/${patientName}/contact-info`;
    return this.http.post<ContactInfo>(url, contactInfo);
  }

  getContactPoints(patientName: string) {
    // const url = `/api/patients/${patientName}/contact-points`;
    const url = `/mock/contact-points`;
    return this.http.get<ContactPoint[]>(url);
  }

  getContactPoint(patientName: string, contactPointName: string): any {
    const url = `/mock/patients/${patientName}/contact-points/${contactPointName}`;
    return this.http.get<ContactPoint>(url);
  }

  createContactPoint(patientName: string, contactPoint: ContactPoint): any {
    const url = `/mock/patients/${patientName}/contact-points`;
    return this.http.post<ContactPoint>(url, contactPoint);
  }

  checkData(patientName: string): any {
    const url = `${this.syncpatientUrl}/${patientName}:sync-data-with-national-register`;
    return this.http.get(url);
  }

  checkEidData(patient: any, eidData: any): Observable<any> {
    const url = `${this.syncpatientUrl}/eid`;
    return this.http.post<any>(url, { patient: patient, userInfo: eidData });
  }

  updateContactPoints(patientName: ResourceName, changes: ContactPointChanges): Observable<ContactPoint[]> {
    const url = `${this.baseUrl}/${patientName}/contactpoints:batchChanges`;
    return this.http.patch<{ data: ContactPoint[] }>(url, changes).pipe(pluck('data'));
  }

  getGpForPatient(patientName: string): Observable<GP | undefined> {
    const url = `${this.baseUrl}/${patientName}/gp`;

    return this.http.get<GetGPForPatientResponse>(url).pipe(
      pluck('gp'),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of(undefined);
        }
        return throwError(err);
      })
    );
  }

  updateGpForPatient(gp: GP): Observable<GP> {
    const url = `${this.baseUrl}/${gp.patientName}/gp`;

    return this.http.patch<GetGPForPatientResponse>(url, gp).pipe(pluck('gp'));
  }

  deleteGpForPatient(patientName: string): Observable<void> {
    const url = `${this.baseUrl}/${patientName}/gp`;

    return this.http.delete<void>(url);
  }

  searchPatientsWithSuggestions(p: EidPersonInfo): Observable<SuggestedPatientsResponse> {
    const url = `${this.baseUrl}/patients:searchWithSuggestions`;
    const search = {
      pageSize: 5,
      pageToken: '',
      advanced: true,
      familyName: p.name,
      givenName: p.givenName,
      inss: p.sub,
      birthDate: p.birthdate,
      gender: p.gender,
      orderBy: 'birthDate',
      address: p.address,
      view: 'FULL',
    };
    return this.http.post<SuggestedPatientsResponse>(url, search).pipe(
      map((resp: any) => {
        return {
          match: resp.match,
          suggestions: resp.suggestions?.data,
        } as SuggestedPatientsResponse;
      })
    );
  }

  batchUpsertAddressesWithContactPoints(patientName: string, data: AddressWithContactPoints, updateMask: string) {
    const url = `${this.baseUrl}/${patientName}:batch-upsert-addresses-with-contact-pointsv2`;

    return this.http.post<BatchUpsertAddressesWithContactPointsResponse>(
      url,
      {
        addressesWithContactPoints: [data],
      },
      {
        params: { updateMask },
      }
    );
  }

  syncInss(patientName: ResourceName, forceSsinHistory: boolean = false): Observable<any> {
    const url = `${this.syncpatientUrl}/${patientName}/inss:sync`;

    return this.http.post<any>(
      url,
      {
        forceSsin: forceSsinHistory,
      },
      {
        headers: timeoutHeaders({ warningMillis: 30000, errorMillis: 60000 }),
      }
    );
  }

  private queryParamsForEmptyNameFields(personName: PersonName): Params {
    const nullValues = Object.entries(personName).filter(
      ([k, v]) => v === null || v === undefined || v === '' || v.length === 0
    );
    const queryParams = [];
    let httpParams: HttpParams = new HttpParams();

    nullValues.forEach((value) => queryParams.push(`personname.${value[0]}`));
    httpParams = httpParams.append('updateMask', queryParams.join(','));

    if (httpParams.get('updateMask') !== '') {
      return httpParams;
    }

    return null;
  }
}

interface GetGPForPatientResponse {
  gp: GP;
}

export interface BatchUpsertAddressesWithContactPointsResponse {
  patient: string;
  addresses: PatientAddress[];
  contactPoints: ContactPoint[];
  deletedContactPoints: string[];
}

export interface MergePatient {
  name: string;
  sendVersion: string;
}

export interface MergePatients {
  mainPatient: MergePatient;
  mergingPatient: MergePatient;
}

export interface RegisterPatientRequest {
  createTherapeuticRelation: boolean;
  createAdministrativeRelation: boolean;
  motivation: string;
  type: 'EID_READ' | 'EID_MANUAL';
  reasonNoCardReading: ReasonNoCardReadingType;
  reasonFreeText?: string;
  identifiers: { inss: string } & ({ eid: string } | { isi: string });
  createEhealthTherapeuticRelation: boolean;
}
