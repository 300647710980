import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { FeatureFlagService } from './state/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagResolver  {
  constructor(private featureFlagService: FeatureFlagService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const flagNames = route.data?.flagNames ?? [];
    this.featureFlagService.loadMany(...flagNames).subscribe();
    // non-blocking resolver
    return of(true);
  }
}
